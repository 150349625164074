import react, { useState } from 'react';
import Grid from '@material-ui/core/Grid'

import MediaElement from '../MediaElement/MediaElement';
import classes from './Post.module.sass';
import Footer from '../Footer/Footer';

const Post = (props) => {
    const [postStep, setPostStep] = useState(0)
    const [allShown, setAllShown] = useState(false)


    const onNextStep = () => {
        if(postStep+1 < props.content.content.length) {
            setPostStep(postStep+1)
        } else {
            setAllShown(true)
        }
    }

    const restartSteps = () => {
        setPostStep(0)
        setAllShown(false)
    }

    return (
        <>
    
                <div className={classes.Post}>
                    {!allShown ? 
                        <>
                        <h1>{props.content.title}</h1>
                        {/* <MediaElement 
                            nextStep={() => onNextStep(postStep+1)}
                            type={props.content.content[postStep].type} 
                            text={props.content.content[postStep].text}
                            title={props.content.content[postStep].title ? props.content.content[postStep].title : null}
                            folder={props.content.id}
                            media={props.content.content[postStep].media && props.content.content[postStep].media.src}
                            caption={(props.content.content[postStep].media && props.content.content[postStep].media.caption) && props.content.content[postStep].media.caption}
                        /> */}
                        <MediaElement 
                            step={postStep}
                            nextStep={() => onNextStep(postStep+1)}
                            contentElements={props.content.content[postStep]} 
                            folder={props.content.id}
                        />
                        </>
                    :   <div className={classes.TextInfo}>
                            <p>Du hast nun alle Informationen zu dieser Filmspur gesehen. Suche nun den QR-Code und scanne ihn mit dem QR-Code Scanner, um zur nächsten Filmspur zu gelangen.</p>
                            <button style={{marginLeft: "auto"}} class="button" onClick={restartSteps}>
                                Informationen wiederholen
                            </button>
                        </div>
                    }
                </div>
            <Footer postId={props.content.id} openScanner={allShown}/>
        </>
    )
}

export default Post;