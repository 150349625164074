import React, {useState, useContext} from 'react'
import {UserContext} from '../../shared/UserContext'
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Auth from '../Auth/Auth'
import SideDrawer from '../../components/Header/Navigation/SideDrawer/SideDrawer';

const Layout = props => {
    const [showSideDrawer, setShowSideDrawer] = useState(false);
    const {isAuth, setIsAuth} = useContext(UserContext);

    const drawerToggleClicked = () => {
        setShowSideDrawer(!showSideDrawer);
    }
    
    return (
        <>
            <Header
                toggleClicked={drawerToggleClicked} />
            <SideDrawer 
                open={showSideDrawer} 
                closed={drawerToggleClicked} />
            <div>
            {props.children}
            </div>
        </>
    );
}

export default Layout;