import React, { useState } from 'react'

import { nx } from '../../../shared/utility';
import {Button, Grid, FormControl, InputLabel, Input, Select, FormHelperText} from '@material-ui/core';
import classes from './AdminLogin.module.sass'

const AdminLogin = ({setIsAdmin}) => {

    const [adminPass, setAdminPass] = useState('');
    
    const checkAdminPass = () => {
        console.log("admin pw:", nx(adminPass))
        if(nx(adminPass) == 7502517369061756) {
           setIsAdmin(true)
        }
    }

    return (
        <Grid className={classes.AdminForm} container justify="center">
            <Grid item xs={12} style={{'marginTop': '20px'}}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="for-pw">Admin Passwort</InputLabel>
                    <Input type="password" id="for-pw" aria-describedby="for-helper-text" 
                    value={adminPass}
                    onChange={(e) => setAdminPass(e.target.value)} />
                    <FormHelperText id="for-helper-text">Passwort bei kinematografie.ch beantragen</FormHelperText>
                </FormControl>
            </Grid>
            <Button style={{'marginTop': '20px'}} type="button" variant="contained" onClick={checkAdminPass}> 
                Login
            </Button>
        </Grid>
    )
}

export default AdminLogin
