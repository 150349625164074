import React, { useState, useContext } from 'react'
import classes from './SartPos.module.sass'
import QrReader from 'react-qr-reader';
import { startChecked } from '../../shared/api-calls';
import { UserContext } from '../../shared/UserContext';
import { useHistory } from "react-router-dom";
import { Button } from '@material-ui/core';



const StartPos = () => {
    const [qrRead, setQrRead] = useState(null)
    const [step, setStep] = useState(0);
    const {isAuth, setIsAuth} = useContext(UserContext);
    const history = useHistory();
    const handleQRError = (error) => {
        console.log(error)
    }

    const getStartSentence = (startPos) => {
        console.log(startPos)
        if(startPos === 'start_1') { console.log("hhhtht")}
        switch (startPos) {
            case 'start_1':
                return "an einem Laternenpfahl beim Weltpostdenkmal";
            case 'start_2': 
                return "an einem Hydranten beim Mosesbrunnen"
            case 'start_3': 
                return "an einer Glasvitrine vor der Post an der Kramgasse 1"
            case 'start_4': 
                return "an einem Steinpoller beim Gerechtigkeitsbrunnen"
            case 'start_5': 
                return "an einem Regenfallrohr im Unteren Gerechtigkeitsgässchen"
            case 'start_6': 
                return "am Zähringerdenkmal"
            case 'start_7': 
                return "beim Rathaus am Lischettibrunnen"
            case 'start_8': 
                return "an einem Strassenschild am Mani-Matter-Stutz"
            case 'start_9': 
                return "an einem Regenfallrohr im Metzgergässchen"
            case 'start_10': 
                return "an einem Regenfallrohr im Schützengässchen"
            case 'start_11': 
                return "an einem Regenfallrohr im Ryffligässchen, zwischen Neuengasse und Aarbergergasse"
            case 'start_12': 
                return "an einem Laternenpfahl im Casinopark"
            case 'start_13': 
                return "an einem Regenfallrohr im Amthausgässchen"
            case 'start_14': 
                return "an einem Regenfallrohr im Käfiggässchen"
            case 'start_15': 
                return "an einem Regenfallrohr im Storchengässchen"
            case 'start_16': 
                return "an einem Laternenpfahl an der Europapromenade"
            default:
                return "Start position nicht gefunden bitte kontaktiere deine Lehrperson"
        }
    }

    const handleQRScan = (data) => {
        if(data && data != qrRead) {
            console.log(data)
            console.log(isAuth.userStartPos)
            if(data===isAuth.userStartPos) {
                setQrRead(data);
                startChecked(isAuth.id)
                    .then((checked) => {
                        history.push("/post_1");
                    })
            }
        }
    }

    return (
        <div>
            { step === 0 ?
                <>
                    <h1>Herzlich willkommen zur Filmschnitzeljagd!</h1>
                    <div className={classes.TextWrapper}>
                        <p>Vor über 120 Jahren entstanden die ersten Filmaufnahmen der Stadt Bern. Sie zeigen eine Stadt an der Schwelle zur Moderne, als neue Verkehrsmittel aufkommen und internationale Gäste die Schweiz bereisen. Bern sah damals anders aus als heute, aber die Spuren der Vergangenheit sind immer noch sichtbar.</p>
                        <p>
                            Mithilfe der historischen Filmaufnahmen und einiger Hinweise kannst Du die damaligen Drehorte wiederfinden. Viel Spass auf der Spur der ersten Berner Filme!
                        </p>
                        <Button onClick={() => setStep(1)} style={{'marginTop': '20px'}} type="submit" variant="contained">
                            Weiter
                        </Button>
                    </div>
                </>
                :
                <>
                    <h1>Zum Starten den QR-Code am Startpunkt scannen.</h1>
                    <div className={classes.TextWrapper}>
                        <p>Dein Startpunkt befindet sich:</p>
                        {isAuth && <p style={{marginTop: "10px"}}><strong>{getStartSentence(isAuth.userStartPos)}</strong></p>}
                    </div>
                    <div className={classes.QRCode}>
                        <QrReader
                            delay={500}
                            //facingMode="rear"
                            //style={previewStyle}
                            //style={{width: '100%', height: '100%'}}
                            onError={handleQRError}
                            onScan={handleQRScan} />
                    </div>
                </>
            }
        </div>
    )
}

export default StartPos
