import React, { useState, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import {Button, Grid, FormControl, InputLabel, Input, FormHelperText} from '@material-ui/core';

import {UserContext} from '../../shared/UserContext'
import api from '../../shared/axios-api'
import { getFormData } from '../../shared/utility';
import { getUserData } from '../../shared/api-calls';

import classes from './Auth.module.sass';
import useFullPageLoader from '../../hooks/useFullPageLoader';

//class Auth extends Component {
const Auth = (props) => {   
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [authName, setAuthName] = useState('')
    const [authPwd, setAuthPwd] = useState('')

    const {isAuth, setIsAuth} = useContext(UserContext);

    useEffect(() => {
        if(localStorage.getItem('schnitzelJgd-user')) {
            // get users info from db
            getUserData(localStorage.getItem('schnitzelJgd-user'))
                .then(userData => {
                    console.log("userData:",userData)
                    const user = {
                        id: userData.id,
                        userCurrentPost: userData.userCurrentPost,
                        userDirection: userData.userDirection,
                        userStartPos: userData.userStartPos
                    }
                    console.log("already logged in: ", user)
                    setIsAuth(user)
                })
        }
        
    }, []);
    const onAuth = (e) => {
        e.preventDefault();
        showLoader();
        const data = { 
            userName: authName,
            userPwd: authPwd }
        
        let dataToSend = getFormData(data);
        
        api.post("api/login_check.php", dataToSend)
        .then(response => {
            hideLoader();
            console.log(response.data)
            if(response.data) {
                setIsAuth(response.data[0])
                console.log(isAuth)
                localStorage.setItem('schnitzelJgd-user', response.data[0].id);
                localStorage.setItem('schnitzelJgd-currPos', response.data[0].userCurrentPost);
                localStorage.setItem('schnitzelJgd-direction', response.data[0].userDirection);
                
                if(response.data[0].startPos !== 'checked') {
                    localStorage.setItem('schnitzelJgd-startPos', response.data[0].userStartPos);
                } else {
                    localStorage.setItem('schnitzelJgd-startPos', 'checked');
                }
            } else {
                setIsAuth("not Logged In")
            }
        })
        .catch(err => {
            console.log(err)
            hideLoader();
        })
    }

    let authRedirect = null;
    if(props.isAuthenticated) {
        authRedirect = <Redirect to="/" />;
    }

    return (
        <div className={classes.AuthForm}>
            {loader}
            <h2>Login</h2>
            <form onSubmit={onAuth}>
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                                <InputLabel htmlFor="for-class">Gruppenname</InputLabel>
                                <Input 
                                    id="for-class" aria-describedby="helper-text-class" 
                                    value={authName} 
                                    onChange={(e) => setAuthName(e.target.value)} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{'marginTop': '20px'}}>
                        <FormControl fullWidth>
                                <InputLabel htmlFor="for-count">Passwort</InputLabel>
                                <Input id="for-count" aria-describedby="for-helper-text" 
                                value={authPwd}
                                onChange={(e) => setAuthPwd(e.target.value)} />
                        </FormControl>
                    </Grid>
                    <Button style={{'marginTop': '20px'}} type="submit" variant="contained">
                        Login
                    </Button>
                </Grid>
            </form>
        </div>
    );
}

export default Auth;
