import firebase from "firebase/app";
import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCSMfRKW9HUukYdLY4hr5camMPO-bO6Tw4",
    authDomain: "schnitzeljagt-2af7d.firebaseapp.com",
    projectId: "schnitzeljagt-2af7d",
    storageBucket: "schnitzeljagt-2af7d.appspot.com",
    messagingSenderId: "118204709861",
    appId: "1:118204709861:web:58f12f7785ab9e23d98d0c"
  };

  firebase.initializeApp(firebaseConfig);

  const storage = firebase.storage();

  export { storage, firebase as defaulte };