import classes from './Finished.module.sass'

function Finished() {
    return (
        <div className={classes.Finished}>
            <h1>Herzlichen Glückwunsch</h1>            
            <p>Du hast alle Posten erfolgreich gefunden.</p>
            <p>Bitte begib Dich zum definierten Ausgangspunkt zurück.</p>
            <p>Weitere interessante Projekte rund um Medienwissenschaften findest du auf <a href='https://kinematografie.ch/'>kinematografie.ch</a></p>
        </div>
    )
}

export default Finished
