import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { UserContext } from '../../shared/UserContext';

import { AiOutlineCloseCircle, AiOutlineArrowDown, AiFillCamera, AiOutlineQrcode } from 'react-icons/ai';
import QrReader from 'react-qr-reader';
import useGeolocation from '../../hooks/useGeolocation';
import api from '../../shared/axios-api';
import { getFormData } from '../../shared/utility';
import { completePost, uploadFile, uploadImage } from '../../shared/api-calls';

import classes from './Footer.module.sass';
import useFullPageLoader from '../../hooks/useFullPageLoader';
//import MapContainer from './MapContainer/MapContainer';
import MapContainer from '../../container/MapContainer/MapContainer';
import { storage } from '../../firebase';

const Footer = props => {
    const [picture, setPicture] = useState(null);
    const [footerExtended, setFooterExtended] = useState(false);
    const [footerContent, setFooterContent] = useState('MAP');
    const {isAuth, setIsAuth} = useContext(UserContext);
    let history = useHistory()
    const location = useGeolocation();
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    useEffect(() => {
        if(props.openScanner) {
            setFooterExtended(true)
            setFooterContent('QR')
        } else {
            setFooterExtended(false)
            setFooterContent('MAP')
        }
    }, [props.openScanner]);

    let url = props.postId;
    let classList = [classes.Footer]
    
    footerExtended && classList.push(classes.Extended)

    const changeFooterContent = (content) => {
        setFooterContent(content);
    }

    const handleTakePicture = e => {
        setFooterContent("CAM");
        setFooterExtended(true);
        if(e.target.files[0]) {
            setPicture(e.target.files[0])
        }
    }

    const extendFooter = () => {
        if(!footerExtended) {
            setFooterExtended(true);
        }
    }

    const closeFooter = () => {
        setFooterExtended(false);
        setFooterContent('MAP');
        setPicture(null);
    }
   
    const handleFileUpload = e => {
        if(picture) {
            showLoader();
            console.log(isAuth)
            uploadImage(picture, isAuth.id, isAuth.userCurrentPost)
                .then(() => {
                    hideLoader();
                    closeFooter();
                });
        }
        // if(picture) {
        //     console.log(picture)
        //     showLoader()
        //     const uploadTask = storage.ref(`images/${picture.name}`).put(picture);
        //     //loader einfügen
        //     uploadTask.on(
        //         "state_changed",
        //         snapshot => {},
        //         error => {
        //             console.log(error)
        //         },
        //         () => {
        //             hideLoader()
        //             closeFooter()
        //         }
        //     )
        // }
    }

    const handleQRScan = (data) => {
        if(data) {
            if(data===url) {
                completePost(isAuth.userCurrentPost,isAuth.id)
                .then((newPost) => {
                    let newAuth = {...isAuth, userCurrentPost: newPost}
                    setIsAuth(newAuth)
                    console.log("newAuth:", isAuth)
                    history.push("/post_"+newPost);
                })
            }
            //     console.log("reading success")
            //     const data = { 
            //         postId: props.postId,
            //         userId: localStorage.getItem('schnitzelJgd-user')
            //     }
            //     let dataToSend = getFormData(data);

                
            //     api.post("api/update_post_found.php", dataToSend)
            //         .then(response => {
            //             console.log(response);
            //             localStorage.setItem('schnitzelJgd-currPos', response.data)
            //             // change current post
            //         }).catch(err => {
            //             console.log('error when scanning code', err)
            //         })
            //     console.log(data + '?id=userHash')
            // }
        }
    }

    const handleQRError = (error) => {
        console.log(error)
    }

    let content = null;
    if(footerContent==="QR") {
        content = (
            <div className={classes.QRCode}>
                <QrReader
                    delay={500}
                    //facingMode="rear"
                    //style={previewStyle}
                    //style={{width: '100%', height: '100%'}}
                    onError={handleQRError}
                    onScan={handleQRScan} />
            </div>
        )
    }

    if(footerContent==="CAM") {
        content = (
            <div>
                {!picture ? <p>Picture uploading...</p>
                    : <img className={classes.UploadedImg} src={URL.createObjectURL(picture)} />}
            </div>
        )
    }
            
    return (
        <div onClick={!footerExtended ? extendFooter : null} 
            className={classList.join(" ")}>
            {loader}
            <div className={classes.MapContainer} style={(footerContent!=='MAP') ? {display: 'none'} : {display: 'block'}}>
                <MapContainer />
                {/* {location.error ? 
                    <p>Du hast Deinen Standort deaktiviert</p> 
                :   <MapContainer /> } */}
            </div>
            {content}
            <div className={classes.IconWrapper}>
                {footerExtended && !picture && 
                    <AiOutlineArrowDown onClick={closeFooter} />}
                {footerExtended && picture && 
                    <AiOutlineCloseCircle class={classes.Red} onClick={closeFooter} />}
                {(picture && footerExtended) ? <span className={classes.Upload} onClick={handleFileUpload}>Upload</span> 
                :
                <>
                    <label for="icon-button-file" >
                        <AiFillCamera />
                        <input 
                            name="image"
                            onChange={handleTakePicture}
                            style={{display: "none"}}
                            accept="image/*" 
                            id="icon-button-file" 
                            type="file"
                            capture="environment" />
                    </label>
                    <AiOutlineQrcode onClick={() => changeFooterContent("QR")} />
                </>}
            </div>
        </div>
    )
}

export default Footer;