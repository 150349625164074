import React, { useEffect, useState } from 'react'
import classes from './GenerateUsers.module.sass'

import { CSVLink, CSVDownload } from "react-csv";
import api from '../../../shared/axios-api'
import {Button, Grid, FormControl, InputLabel, Input, Select, FormHelperText} from '@material-ui/core';
import { getFormData } from '../../../shared/utility';
import GeneratedUser from '../../../components/GeneratedUser/GeneratedUser';

const GenerateUsers = () => {
    const [className, setClassName] = useState('');
    const [userCount, setUserCount] = useState('');
    const [cityLocation, setCityLocation] = useState('')
    const [cities, setCities] = useState([]);
    const [generatedUsers, setGeneratedUsers] = useState(null)

    useEffect(() => {
        api.get("api/get_cities.php")
            .then(response => {
                //console.log(response.data)
                setCities(response.data)
            })
            .catch(err => {
                console.log("Error when catching cities: ", err)
            })
    }, []);   
    
    const addUsers = (e) => {
        e.preventDefault();
        
        const data = { 
            className: className,
            userCount: userCount, 
            userLocation: cityLocation
        }
        
        let dataToSend = getFormData(data);
        
        api.post("api/add_users.php", dataToSend)
            .then(response => {
            if(response.data) {
                console.log(response.data)
                setGeneratedUsers(response.data)
            } else {
            }
        })
        .catch(err => {
            console.log(err)
        })
    }
    
    return (
        <div className={classes.GenerateForm}>
            <h2>Neue Gruppen erstellen</h2>
            <form onSubmit={addUsers}>
                <Grid container justify="center">
                    <Grid item xs={12} style={{'marginTop': '20px'}}>
                        <FormControl fullWidth>
                                <InputLabel htmlFor="for-class">Ort</InputLabel>
                                <Select
                                    id="for-class" aria-describedby="helper-text-class" 
                                    value={cityLocation} 
                                    onChange={(e) => setCityLocation(e.target.value)}>
                                        {cities.map(city => {
                                            return (
                                                <option 
                                                    key={city.plz}
                                                    value={city.plz}>
                                                        {city.name}
                                                </option>)
                                        })}
                                </Select>
                                <FormHelperText id="helper-text-class">Wähle den Standort.</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{'marginTop': '20px'}}>
                        <FormControl fullWidth>
                                <InputLabel htmlFor="for-class">Klasse</InputLabel>
                                <Input 
                                    id="for-class" aria-describedby="helper-text-class" 
                                    value={className} 
                                    onChange={(e) => setClassName(e.target.value)} />
                                <FormHelperText id="helper-text-class">Bitte Klassenname angeben.</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{'marginTop': '20px'}}>
                        <FormControl fullWidth>
                                <InputLabel htmlFor="for-count">Anzahl</InputLabel>
                                <Input type="number" id="for-count" aria-describedby="for-helper-text" 
                                value={userCount}
                                onChange={(e) => setUserCount(e.target.value)} />
                                <FormHelperText id="for-helper-text">Anzahl der Gruppen</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Button style={{'marginTop': '20px'}} type="submit" variant="contained">
                        Erstellen
                    </Button>
                </Grid>
            </form>
            {generatedUsers?.length > 0 && (
                <div className={classes.GeneratedUsers}>
                    <h2>Sie haben folgende Gruppen erstellt:</h2>
                    <table>
                    {generatedUsers.map((user,i) => {
                        return <GeneratedUser 
                            key={i} 
                            counter={i+1} 
                            userName={user[0]} 
                            userPwd={user[1]}
                            userStart={user[2]} />
                    })}
                    </table>
                    <Grid container justify="center">
                    <Button style={{'marginTop': '20px'}} type="submit" variant="contained">
                        <CSVLink 
                            style={{'color':'#000', 'textDecoration': 'none'}} 
                            filename={"Schnitzeljagd_Gruppenliste.csv"}
                            data={generatedUsers}>Download CSV</CSVLink></Button>
                        </Grid>
                </div>
            )}
        </div>
    )
}

export default GenerateUsers