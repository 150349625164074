import React from 'react'
import classes from './GeneratedUser.module.sass'

const GeneratedUser = ({counter, userName, userPwd, userStart}) => {
    return (
            <tr>
                <td>{counter}</td>
                <td>{userName}</td>
                <td>{userPwd}</td>
                <td>{userStart}</td>
            </tr>
    )
}

export default GeneratedUser
