import React, { useState } from 'react'
import FullPageLoader from '../components/FullPageLoader/FullPageLoader'

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    return [
        loading ? <FullPageLoader success={success} /> : null,
        () => setLoading(true),
        () => {
            setSuccess(true)
            setTimeout(() => {
                setLoading(false) 
            }, 550)
        }
    ]
}

export default useFullPageLoader
