import React from 'react'

const UserImages = () => {
    return (
        <div>
            <h2>Bilder</h2>
        </div>
    )
}

export default UserImages
