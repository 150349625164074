import React, {useContext} from 'react'
import {useHistory} from "react-router-dom";
import {completePost} from '../../../../../shared/api-calls'
import {UserContext} from '../../../../../shared/UserContext';
import classes from './Menu.module.sass';
import MenuItem from './MenuItem/MenuItem';
import {Button} from '@material-ui/core'

const { REACT_APP_DEBUG_MODE } = process.env;

const NavigationItems = (props) => {
    const {isAuth, setIsAuth} = useContext(UserContext);
    let history = useHistory();
    
    const onCompletePost = () => {
        completePost(isAuth.userCurrentPost,isAuth.id)
        .then((newPost) => {
            let newAuth = {...isAuth, userCurrentPost: newPost}
            setIsAuth(newAuth)
            console.log("newAuth:", isAuth)
            history.push("/post_"+newPost);
        })
        
    }

    let menuItems = (
        <>
            {!isAuth ? <MenuItem link="/">Login</MenuItem> :
                <MenuItem link="/logout">Logout</MenuItem>}
            <MenuItem link="/admin">Admin</MenuItem>
            {REACT_APP_DEBUG_MODE === 'true' && 
            <Button onClick={onCompletePost}>_Complete Post</Button>}
        </>
    )
    
    return (
        <ul className={classes.Menu}>
            {menuItems}
        </ul>
    );
}


export default NavigationItems;