import React, { useState, useEffect } from 'react'

export const useGeolocation = () => {
    const [location, setLocation] = useState({
        loaded: false,
        coordinates: { lat: 0, lng: 0 }
    });

    const onSuccess = location => {
        setLocation({
            loaded: true,
            coordinates: {
                lat: location.coords.latitude,
                lng: location.coords.longitude
            }
        });
    }

    const onError = error => {
        setLocation({
            loaded: true,
            error,
        });
    }

    useEffect(() => {
        
        if( !("geolocation" in navigator)) {
            onError({
                code: 0,
                message: "Geolocation is not supported"
            });
        }
        //navigator.geolocation.getCurrentPosition(onSuccess, onError);
        navigator.geolocation.watchPosition(onSuccess, onError)
    }, [])

    return location;
}


export default useGeolocation;