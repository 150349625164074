import React, { useState, useEffect, useRef, useContext } from 'react';
import {UserContext} from '../../shared/UserContext'
import {Link, Switch, Route, useHistory} from 'react-router-dom';

import Finished from '../../components/Finished/Finished'
import Post from '../../components/Post/Post';
import Footer from '../../components/Footer/Footer';
import classes from './Posts.module.sass';
import postData from './PostsData.json';

const Posts = () => {
    const {isAuth, setIsAuth} = useContext(UserContext);
    const [extendedIds, setExtendedIds] = useState([0])
    const mediaRefs = useRef([]);
    let history = useHistory();
    mediaRefs.current = [];
    
    useEffect(() => {
        if(!isAuth) {
            history.push("/");  
        } else {
            let curr_pos = localStorage.getItem('schnitzelJgd-currPos')
            history.push("/post_" + curr_pos);  
        }
    }, []);

    const closeExtended = (id) => {
        console.log("extened ids: ", extendedIds);
        console.log("delete: " + id)
        const newExtended = extendedIds.filter(el => el !== id);
        console.log("new extended: ", newExtended)
        setExtendedIds(newExtended)
    }

    const addExtended = (id) => {
        console.log("extened ids: ", extendedIds);
        const newExtended = [...extendedIds]
        !newExtended.includes(id) && newExtended.push(id)
        setExtendedIds(newExtended)
    }

    const addToMediaRefs = (el) => {
        if(el && !mediaRefs.current.includes(el)) {
            mediaRefs.current.push(el) 
        }
    }

    let posts = postData.map(post => {
        return (
            <Route key={post.id} path={"/"+post.id}>
                <Post extendedIds={extendedIds} 
                    addRef={addToMediaRefs}
                    content={post} 
                    extend={(id) => addExtended(id)}
                    close={(id) => closeExtended(id)}
                    />
            </Route>
        )
    });

    return (
        <div className={classes.Posts}>
            <Switch>
                <Route path="/post_0">
                    <Finished />
                </Route>
                {posts} 
            </Switch>
        </div>
    )
}

export default Posts;