import React, { useState, useEffect } from 'react'
import { getAllFrom, completePost, startChecked } from '../../../shared/api-calls'

import { Button, Divider, FormControl, InputLabel, Select, FormHelperText } from '@material-ui/core';

const HelpUsers = () => {
    const [classes, setClasses] =useState(null)
    const [groups, setGroups] =useState(null)
    const [selectedClass, setSelectedClass] = useState(null)
    const [selectedGroup, setSelectedGroup] = useState(null)

    const onClassChange = (e) => {
        setSelectedClass(e.target.value)
        getAllFrom('TUsers', ['userName', 'userId', 'userCurrentPost', 'userStartPos'], `userClassName='${e.target.value}'`)
            .then(resp => {
                console.log(resp)
                setGroups(resp)
            })
    }

    const onGroupChange = (e) => {
        setSelectedGroup(e.target.value)
    }

    const goToNextPost = () => {
        
        const groupInfos = groups.filter(g => g.userName === selectedGroup)
        if(groupInfos[0].userStartPos === 'checked') {
            completePost(groupInfos[0].userCurrentPost, groupInfos[0].userId)
                .then((newPost) => {
                    console.log("Post updated...")

                })
        } else {
            startChecked(groupInfos[0].userId)
                .then((checked) => {
                    console.log("Start set to checked")
                })
        }
    } 

    useEffect(() => {
        getAllFrom('TUsers', 'userClassName', '')
            .then(resp => {
                let classes = resp.map(o => o.userClassName)
                let filtered = resp.filter(({userClassName}, index) => !classes.includes(userClassName, index+1))
                setClasses(filtered)
            })
    }, []);

    return (
        <div style={{padding: '20px'}}>
            <h2>Gruppen funktionen</h2>
            <FormControl fullWidth style={{marginTop: '20px'}}>
                <InputLabel htmlFor="for-class">Klasse</InputLabel>
                <Select
                    id="for-class" aria-describedby="helper-text-class" 
                    value={selectedClass} 
                    onChange={onClassChange}>
                        {classes?.map((c, i) => {
                            return (
                                <option 
                                    key={i}
                                    value={c.userClassName}>
                                        {c.userClassName}
                                </option>)
                        })}
                </Select>
                <FormHelperText id="helper-text-class">Wähle die Klasse.</FormHelperText>
            </FormControl>
            {groups &&
                <FormControl fullWidth style={{marginTop: '20px'}}>
                    <InputLabel htmlFor="for-group">Gruppe</InputLabel>
                    <Select
                    id="for-group" aria-describedby="helper-text-class" 
                    value={selectedGroup} 
                    onChange={onGroupChange}>
                        {groups?.map((g, i) => {
                            return (
                                <option 
                                    key={i}
                                    value={g.userName}>
                                        {g.userName}
                                </option>)
                        })}
                    </Select>
                    <FormHelperText id="helper-text-group">Wähle die Gruppe.</FormHelperText>
                </FormControl>
            }
            {selectedGroup &&
                <>
                <div style={{marginTop:'20px'}}>
                    <strong>Gruppe: {selectedGroup}</strong><br/>
                    <Button 
                        style={{marginTop:'20px'}}
                        variant="contained" 
                        onClick={goToNextPost}>
                            Nächster Posten
                    </Button>
                </div>
                </>
            }
        </div>
    )
}

export default HelpUsers
