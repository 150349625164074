import classes from './Backdrop.module.sass';

const Backdrop = (props) => (
    props.show ? 
        <div 
            className={classes.Backdrop}
            onClick={props.clicked}>
        </div> : null
);

export default Backdrop;