import React from 'react'
import {GoogleMap, useLoadScript, Marker, InfoWindow} from '@react-google-maps/api'
import useGeolocation from '../../hooks/useGeolocation';
import mapStyles from'./mapStyles';
import markerIcon from './red_dot.png';

const mapContainerStyle = {
    width: '100%',
    height: '100%'
}

const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    //zoomControl: true
}

const MapContainer = () => {
    const location = useGeolocation();
    
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });
    if(loadError) return "Error loading map";
    if(!isLoaded) return "Map is loading";

    return (
        <GoogleMap 
            mapContainerStyle={mapContainerStyle}
            zoom={16}
            center={location.coordinates}
            options={options}
            >
            <Marker position={location.coordinates} 
                    icon={{
                        url: markerIcon,
                        scaledSize: new window.google.maps.Size(20,20)
                    }} />
        </GoogleMap>
    )
}

export default MapContainer;



