import React from 'react'

import Menu from './Menu/Menu'
import classes from './SideDrawer.module.sass';
import Backdrop from '../../../UI/Backdrop/Backdrop';



const SideDrawer = props => {

    let attachedClasses = [classes.SideDrawer, classes.Close]
    if(props.open) {
        attachedClasses = [classes.SideDrawer, classes.Open]
    }
    
    return(
        <>
            <Backdrop show={props.open} clicked={props.closed} />
            <div className={attachedClasses.join(' ')}>
                <nav>
                    <Menu isAuth={props.isAuth}/>
                </nav>
            </div>
        </>
    );
}

export default SideDrawer;