import React, { useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import {UserContext} from '../../../shared/UserContext';

const Logout = () => {
    const {isAuth, setIsAuth} =  useContext(UserContext);
    useEffect(() => {
        setIsAuth(null);
        localStorage.removeItem('schnitzelJgd-user');
        localStorage.removeItem('schnitzelJgd-currPos');
        localStorage.removeItem('schnitzelJgd-direction');
        localStorage.removeItem('schnitzelJgd-startPos');

    }, []);

    return <Redirect to="/" />;

}


 
export default Logout;