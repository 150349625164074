import classes from './Header.module.sass'
//import Logo from '../Logo/Logo';
import DrawerToggle from './Navigation/SideDrawer/DrawerToggle/DrawerToggle'


const Header = (props) => {

    return (
        <header className={classes.Header}>
                <h3>Schnitzeljagd</h3>
                <DrawerToggle clicked={props.toggleClicked} />
        </header>   
    );
};

export default Header;