import React, { useState, useEffect } from 'react';
import classes from './MediaElement.module.sass';
import Close from '@material-ui/icons/Close';
import { Button } from '@material-ui/core'

const { REACT_APP_POST_FILES } = process.env;

const MediaElement = (props) => {

    let classList = [classes.MediaElement];


    let content = null;

    content = props.contentElements.map((ce, i) => {
        if(ce.type === "text") {
            return <p key={i} dangerouslySetInnerHTML={{__html: ce.content}}></p>
        } else if (ce.type === "img" ) {
            return (
                <figure key={i}>
                    <img src={`${REACT_APP_POST_FILES}files/posts/${props.folder}/${ce.content}`} />
                    {ce.caption && <figcaption>{ce.caption}</figcaption>}
                </figure>
                )
        } else if (ce.type === "video" ) {
            return <video key={i} loop autoPlay muted playsInline controls src={`${REACT_APP_POST_FILES}files/posts/${props.folder}/${ce.content}`} />
        }
    })
   console.log("PROPIS:",props)
    
    // if(props.type.includes('img')) {
    //     media = (
    //         <figure>
    //             <img src={REACT_APP_POST_FILES+"files/posts/" + props.folder + "/" +props.media} alt={props.caption} />
    //             {props.caption && <figcaption>{props.caption}</figcaption>}
    //         </figure>
    //     );
    // } else if(props.type.includes('video')) {
    //     media = (
    //         <figure>
    //             <video controls preload="metadata" src={REACT_APP_POST_FILES+"files/posts/" + props.folder + "/" + props.media + "#t=1.5"}>
    //             </video>
    //             {props.caption && <figcaption>{props.caption}</figcaption>}
    //         </figure>
    //     );
    // } else if(props.type === 'text') {
    //     media = null
    // }

    // if(props.type === 'img' || props.type === 'video') {
    //     classList.push(classes.MediaOnly)
    // }

    return (
        <div className={classList.join(" ")}>
            {props.title && <h4>{props.title}</h4> }
            <div className={classes.ContentBlock}>
                {content}
            </div>
            {/* {templateString}
            {media} */}
            <button class="button" onClick={props.nextStep} style={{display: 'block', float: 'right'}}>
                Weiter
            </button>
        </div>
    )    
}

export default MediaElement;