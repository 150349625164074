import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './MenuItem.module.sass';

const MenuItem = (props) => (
    <li className={classes.MenuItem}>
        <NavLink 
            exact
            activeClassName={classes.Active} 
            to={props.link}>
                {props.children}
        </NavLink>
    </li>
);

export default MenuItem;