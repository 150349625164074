import api from './axios-api';
import { getFormData } from './utility';

export const completePost = (postId, userId) => {
    console.log(postId, userId)
    const data = { 
        postId: postId,
        userId: userId
    }
    let dataToSend = getFormData(data);
    return api.post("api/update_post_found.php", dataToSend)
        .then(response => {
            console.log(response);
            console.log("[Debug:] - Completet Post: " + postId)
            console.log("[Debug:] - Next Post: " + response.data)
            localStorage.setItem('schnitzelJgd-currPos', response.data)
            return response.data;
        }).catch(err => {
            console.log('error when scanning code', err)
        })
}

export const getAllFrom = (table, fields, condition=null) => {
    const data = { 
        table,
        fields: fields,
        condition: condition
    }
    let dataToSend = getFormData(data);
    
    return api.post("api/get_all_from.php", dataToSend)
        .then(response => {
            //console.log(response.data)
            return response.data
        })
        .catch(err => {
            console.log("Error when getting all from "+table+" : ", err)
        })
    }

export const getUserData = (userId) => {
    const data = { 
        userId: userId
    }
    let dataToSend = getFormData(data);
    return api.post("api/get_user_data.php", dataToSend)
        .then(response => {
            console.log('start QR-Code read: ', response);
            return response.data[0];
        }).catch(err => {
            console.log('error when scanning start code', err)
        })
}

export const startChecked = (userId) => {
    console.log(userId)
    const data = { 
        userId: userId
    }
    let dataToSend = getFormData(data);
    return api.post("api/update_start_checked.php", dataToSend)
        .then(response => {
            console.log('start QR-Code read: ', response);
            localStorage.setItem('schnitzelJgd-startPos', 'checked')
            return response.data;
        }).catch(err => {
            console.log('error when scanning start code', err)
        })
}


export const uploadImage = (image, user, post) => {
    console.log(image)
    // return api.post('api/upload_file.php', image, {
    //     headers: {
    //       'Content-Type': image.type
    //     }
    // }).then((response) => {
    //     console.log("[image upload]", response.data)
    // })
    // var formData = new FormData();
    // formData.append("image", image);
    const data = { 
        image: image,
        userId: user, 
        postId: post
    }
    let dataToSend = getFormData(data);
    return api.post('api/upload_file.php', dataToSend, {
        headers: {
        'Content-Type': 'multipart/form-data'
        }
    }).then((response) => {
            console.log("[image upload]", response.data)
        })
}