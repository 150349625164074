import React, {useState, useEffect} from 'react'

import classes from './Admin.module.sass'
import GenerateUsers from './GenerateUsers/GenerateUsers'
import AdminLogin from './AdminLogin/AdminLogin'
import { Button } from '@material-ui/core';

import HelpUsers from './HelpUsers/HelpUsers'
import UserImages from './UserImages/UserImages'


const Admin = () => {
    const [isAdmin, setIsAdmin] = useState('');
    const [mode, setMode] = useState('');

    const modes = [
        {val: 'gen', name: "Gruppen Erstellen"},
        {val: 'help', name: "Helfen"},
        {val: 'img', name: "Bilder"},
    ]

    return (
        <>
            {isAdmin ?
                <AdminLogin setIsAdmin={setIsAdmin} />
            :   <div style={{textAlign: 'center'}}>
                    {modes.map(btn => {
                        return <Button
                            style={{margin: '10px'}}
                            key={btn.val}
                            type="button"
                            variant="contained"
                            onClick={()=>setMode(btn.val)}
                            >{btn.name}</Button>
                    })}
                </div>
            }
            
            {   mode==='gen'    ? <GenerateUsers />
            :   mode==='help'   ? <HelpUsers />
            :   mode ==='img'   ? <UserImages /> : null
        }
            
        </>
    )
}

export default Admin