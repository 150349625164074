import React, {useState} from 'react';
import './App.sass';
import { Switch, Route, Redirect } from "react-router-dom";

import { UserContext } from './shared/UserContext';
import Layout from './container/Layout/Layout';
import Posts from './container/Posts/Posts';
import Admin from './container/Admin/Admin';
import Auth from './container/Auth/Auth';
import Logout from './container/Auth/Logout/Logout';
import StartPos from './components/StartPos/StartPos';


function App() {
  const [isAuth, setIsAuth] = useState(null);
  console.log("Context", isAuth)
  return (
    <div className="App">
      <UserContext.Provider value={{isAuth, setIsAuth}}>
        <Layout>
          <Switch>
            <Route path='/admin'><Admin /></Route>
            <Route path="/logout" ><Logout /></Route>
            {console.log(isAuth?.userStartPos)}
            <Route exact path="/" >
                { !isAuth ? <Auth/> 
                  : (isAuth && isAuth.userStartPos !== 'checked') ? <StartPos />
                  : <Redirect to="/post_1" /> }
            </Route>
            <Posts />
          </Switch>
        </Layout>
      </UserContext.Provider>
    </div>
  );
}

export default App;
